import React from "react";
import PropTypes from "prop-types";
import parsePhoneNumber from "libphonenumber-js/max";

import { Form, Field } from "@deity/falcon-ecommerce-uikit";
import { FormField, FormAddressField } from "../../account/SignIn/FormField";
import { Button, ButtonTypes, GridForm, Select } from "benlux-ecommerce-ui";

const AddressForm = ({
  countries = [],
  id = "",
  autoCompleteSection,
  loading,
  customer
}) => {
  const [country, setCountry] = React.useState("FR");

  const getAutoComplete = attribute =>
    [autoCompleteSection, attribute].filter(x => x).join(" ");

  const phoneValidator = value => {
    try {
      const phoneNumber = parsePhoneNumber(value, country);
      const isValid = phoneNumber.isValid();

      if (!isValid) {
        return "Veuillez saisir un numéro de téléphone valide";
      }

      const type = phoneNumber.getType();

      if (type !== "MOBILE") {
        return "Veuillez saisir un numéro de téléphone mobile";
      }

      return undefined;
    } catch (e) {
      return "Veuillez saisir un numéro de téléphone valide";
    }
  };

  return (
    <Form id={id} i18nId="addressForm">
      <div style={{ fontSize: "13px" }}>
        <span style={{ color: "#EF394F" }}>*</span> champs obligatoires
      </div>
      <GridForm>
        <FormField
          name="firstname"
          required
          displayedLabel={"Prénom"}
          autoComplete={getAutoComplete("given-name")}
          // gridArea={AddressFormArea.firstName}
          style={{ minWidth: 300 }}
        />
        <FormField
          name="lastname"
          required
          autoComplete={getAutoComplete("family-name")}
          // gridArea={AddressFormArea.lastName}
          displayedLabel={"Nom"}
          style={{ minWidth: 300 }}
        />
        <FormAddressField
          name="street1"
          required
          autoComplete={getAutoComplete("address-line1")}
          // gridArea={AddressFormArea.street1}
          displayedLabel={"Adresse"}
          style={{ minWidth: 300 }}
        />
        <FormField
          name="street2"
          autoComplete={getAutoComplete("address-line2")}
          displayedLabel={"Adresse (suite)"}
          style={{ minWidth: 300 }}
        />
        <FormField
          name="postcode"
          required
          autoComplete={getAutoComplete("postal-code")}
          displayedLabel={"Code Postal"}
          style={{ minWidth: 300 }}
        />
        <FormField
          name="city"
          required
          autoComplete={getAutoComplete("address-level2")}
          displayedLabel={"Ville"}
          style={{ minWidth: 300 }}
        />
        <FormField
          name="telephone"
          required
          autoComplete={getAutoComplete("tel")}
          displayedLabel={"Téléphone"}
          validate={[phoneValidator]}
          type="tel"
          style={{ minWidth: 300 }}
        />
        <div>
          <Field
            name="countryId"
            autoComplete={getAutoComplete("country")}
            displayedLabel={"Pays"}
            required={true}
            // style={{ minWidth: 300, display: "none" }}
          >
            {({ form, field }) => (
              <Select
                name="countryId"
                label="Pays"
                autoComplete={getAutoComplete("country")}
                required={true}
                options={countries.map(country => {
                  return { value: country.code, label: country.localName };
                })}
                onChange={x => {
                  form.setFieldValue(field.name, x);
                  setCountry(x);
                }}
                defaultValue={"FR"}
              />
            )}
          </Field>
        </div>
      </GridForm>
      <div
        style={{
          display: "flex",
          justifyContent: customer ? "space-between" : "flex-end",
          width: "100%"
        }}
      >
        <Button
          type={ButtonTypes.Secondary}
          text={"Continuer"}
          as={"submit"}
          loading={loading}
        />
      </div>
    </Form>
  );
};

AddressForm.propTypes = {
  id: PropTypes.string.isRequired,
  submitLabel: PropTypes.string,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      localName: PropTypes.string,
      code: PropTypes.string
    })
  )
};

export default AddressForm;
